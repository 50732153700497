@import url('https://fonts.googleapis.com/css2?family=Anton&family=Raleway:wght@400;600;900&display=swap');

:root {
  --pink--light: #ff81ff;
  --pink--dark: #cc3dcc;
  --green--light: #009100;
  --green--dark: #004c00;
  --white--off: #F7F8FA;
  --darkorange: #4b1a1b;
  --orange-darkbright: #f7971e;
  --orange-mid: #FAB40E;
  --yellow: #F2C64C;
  --grey: #d9d8da;
  --navy: #0f1922;
  --sev_low: #008100;
  --sev_high: #ED230D;
}

.App {
  text-align: left;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: var(--white--off);
}

.anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

.main-content {
  padding-top: 70px;
}

.content {
  max-width: 1100px;
  padding-left: 10px;
  padding-right: 10px;
}

.outer-content {
  max-width: 100vw;
  max-width: 1400px;
  flex-shrink: 0;
  margin-left: -2px;
  margin-right: -2px;
}

.article-content {
  max-width: 500px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-content {
  max-width: 1100px;
}

.pink-icon {
  filter: invert(45%) sepia(67%) saturate(5349%) hue-rotate(277deg) brightness(87%) contrast(92%);
  margin: 20px 0px 20px 0px;
  width: 40%;
}

.rex-bg-light {
  background-color: rgba(247,153,29,1);
  background: linear-gradient(90deg, rgba(247,153,29,1) 0%, rgba(254,207,0,1) 100%);
}

.rex-bg-second {
  background: rgb(254,207,0);
  background: linear-gradient(90deg, rgba(254,207,0,1) 0%, rgba(247,153,29,1) 100%);
}

.rex-bg-white {
  background-color: var(--white--off);
}

.rex-bg-grey {
  background-color: var(--grey);
}

.rex-bg-dark {
  background-color: var(--navy);
}

.rex-font-color-white {
  color: #fff;
}

.rex-font-color-navy {
  color: var(--navy);
}

.rex-font-color-offwhite {
  color: var(--white--off);
}

.rex-font-color-odb {
  color: var(--orange-darkbright);
}

.rex-font-color-do {
  color: var(--darkorange);
}

.rex-font-color-pink {
  color: var(--pink--dark);
}

.rex-font-style-title {
  font-family: 'Anton', sans-serif;
}

.rex-font-style-main, p, h2, th, td, h6, li, div {
  font-family: 'Raleway', sans-serif;
}

.rex-enorm {
  font-size: 8rem;
}

.rex-largest {
  font-size: 5rem;
}

.rex-sub {
  font-size: 3rem;
}

.rex-large {
  font-size: 1.2rem;
  font-weight: 600;
}

.rex-font-main-black {
  font-weight: 900;
}

.rex-button {
  top: 0;
  right: 0;
  transition: all .15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 7px 10px;
  border: 2px solid var(--pink--dark);
  background-color: #fff;
  box-shadow: 6px 6px var(--pink--light);
  text-decoration: none;
  padding: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Anton', sans-serif;
  font-size: x-large;
  color: var(--pink--dark);
}

.rex-button:hover {
  top: 3px;
  right: -3px;
  box-shadow: 3px 3px 0 var(--pink--light);
  color: var(--pink--dark);
}

.rex-button-mini {
  top: -2px;
  right: 0;
  transition: all .15s linear 0s;
  position: relative;
  display: inline-block;
  padding: 7px 10px;
  background-color: var(--white--off);
  box-shadow: 4px 4px var(--darkorange);
  text-decoration: none;
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  color: var(--darkorange);
}

.rex-button-mini:hover {
  top: 0px;
  right: -2px;
  box-shadow: 2px 2px 0 var(--darkorange);
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  color: var(--darkorange);
}

.rex-link {
  text-decoration: none;
  color: var(--darkorange);
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  background: none;
  border: none;
}

.rex-link:hover {
  color: #fff;
  text-decoration: none;
}

.rex-link-pink {
  text-decoration: none;
  color: var(--pink--dark);
}

.rex-link-pink:hover {
  color: var(--pink--light);
  text-decoration: none;
}

.rex-link-white {
  text-decoration: none;
  color: #fff;
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
  font-size: 0.75rem;
}

.rex-link-white:hover {
  color: var(--orange-darkbright);  
  text-decoration: none;
}


.rex-nav{
  padding-top: 10px;
  padding-bottom: 10px;
  border: none;
}

.rex-nav-item {
  padding: 5px 5px 5px 5px;
  font-size: small;
}

.half-container {
  min-width: 200px;
  max-width: 500px;
  justify-self: left;
}

.half-container-small {
  min-width: 200px;
  max-width: 250px;
  justify-self: left;
}

.rex-article-code{
  background-color: lightgray;
  color: var(--sev_high);
  font-family: monospace;
}

.half-container-fixed {
  min-width: 300px;
  max-width: 45%;
}

.half-container-fixed-small {
  min-width: 300px;
  max-width: 65%;
}

.rex-hero-text-container {
  position: relative;
  bottom: -40px;
  z-index: 1;
  padding: 0px 10px 20px 10px;
}


.rex-hero-image {
  width: 100%;
  position: relative;
  bottom: -48px;
  z-index: 1;
  padding: 0px 10px;
}

.rex-hero-image-small {
  width: 100%;
  position: relative;
  bottom: -23px;
  z-index: 1;
  padding: 0px 10px;
  margin: 0px 30px;
}

@media only screen and (max-width: 600px) {
  .rex-hero-image {
    width: 100%;
    position: relative;
    bottom: -32px;
    z-index: 1;
    padding: 0px 10px;
  }
}

.rex-flowchart-div-good {
  border: solid 2px var(--sev_low);
  background-color: #c5ffc5;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  font-weight: 900;
}

.rex-flowchart-div-bad {
  border: solid 2px var(--sev_high);
  background-color: #fcddda;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  font-weight: 900;
}

.footer-icon {
  width: 7vw;
  max-width: 30px;
  height: auto;
}

.footer-wide {
  width: 15vw;
  max-width: 150px;
  height: auto;
}

.step-rtl {
  clip-path: polygon(
  0% 0%, 25% 0%,
  25% 2%, 50% 2%,
  50% 4%, 75% 4%,
  75% 6%, 100% 6%, 
  100% 92%, 75% 92%, 
  75% 92%, 75% 94%, 
  50% 94%, 50% 96%, 
  25% 96%, 25% 98%, 
  0% 98%);
}

.skinny-step-ltr-small {
  background: var(--white--off);
  clip-path: polygon(
  0px 20px,
  20px 20px, 20px 40px,
  0px 40px, 0px 60px,

  20px 60px, 20px 40px,
  40px 40px, 40px 20px,
  60px 20px, 60px 40px,
  100px 40px, 100px 20px, 
  140px 20px, 140px 40px,
  180px 40px, 180px 20px, 
  200px 20px, 200px 60px,
  220px 60px, 220px 40px,
  240px 40px, 240px 20px,
  280px 20px, 280px 0px, 
  300px 0px, 300px 20px, 
  280px 20px, 280px 40px,
  320px 40px, 320px 20px,

  1400px 20px, 1400px 60px,
  0px 60px);
  height: 60px;
  margin-bottom: -2px;
  z-index: 2;
}

.skinny-step-ltr-small-navy {
  background: var(--navy);
  clip-path: polygon(
  0px 20px,
  20px 20px, 20px 40px,
  0px 40px, 0px 60px,

  20px 60px, 20px 40px,
  40px 40px, 40px 20px,
  60px 20px, 60px 40px,
  100px 40px, 100px 20px, 
  140px 20px, 140px 40px,
  180px 40px, 180px 20px, 
  200px 20px, 200px 60px,
  220px 60px, 220px 40px,
  240px 40px, 240px 20px,
  280px 20px, 280px 0px, 
  300px 0px, 300px 20px, 
  280px 20px, 280px 40px,
  320px 40px, 320px 20px,

  1400px 20px, 1400px 60px,
  0px 60px);
  height: 60px;
  margin-bottom: -2px;
  z-index: 2;
}

.top-step-filler {
  height: 40px;
  width: 100%;
  background: var(--white--off);
  z-index: 2;
  margin-top: -2px;
  margin-right: -2px;
}

.middle-top-step-filler {
  height: 40px;
  max-width: 100%;
  background: var(--white--off);
  z-index: 2;
  margin-top: -2px;
  margin-right: -2px;
}

.bottom-step-filler {
  height: 40px;
  width: 100%;
  background: var(--white--off);
  z-index: 2;
  margin-bottom: -2px;
}

.bottom-step-filler-navy {
  height: 40px;
  width: 100%;
  background: var(--navy);
  z-index: 2;
  margin-bottom: -2px;
}

.skinny-step-rtl-small {
  background: var(--white--off);
  clip-path: polygon(0px 0px, 0px 20px,
  20px 20px, 20px 40px,
  40px 40px, 40px 60px,
  80px 60px, 80px 20px,
  60px 20px, 60px 0px,
  80px 0px, 80px 20px,
  120px 20px, 120px 40px,
  140px 40px, 140px 20px,
  160px 20px, 160px 40px,
  180px 40px, 180px 60px,
  220px 60px, 220px 20px,
  240px 20px, 240px 0px,
  260px 0px, 260px 20px,
  240px 20px, 240px 40px,


  300px 40px,

  300px 60px, 300px 0px,



  0px 0px
  );
  height: 60px;
  width: 300px;
  margin-top: -2px;
  z-index: 2;
}

.step-ltr {
  clip-path: polygon(0% 6%, 25% 6%, 
  25% 4%, 50% 4%,
  50% 2%, 75% 2%, 
  75% 0%, 100% 0%, 
  100% 92%, 100% 100%, 
  75% 100%, 75% 98%, 
  50% 98%, 50% 96%, 
  25% 96%, 25% 94%, 
  0% 94%);
}

.topflat-step-ltr {
  clip-path: polygon(0% 0%, 100% 0%, 
  100% 92%, 100% 100%, 
  75% 100%, 75% 98%, 
  50% 98%, 50% 96%, 
  25% 96%, 25% 94%, 
  0% 94%);
}

.topflat-step-ltr-skinny {
  height: 40px;
  clip-path: polygon(0% 0%, 100% 0%, 
  100% 0%, 100% 100%, 
  75% 100%, 75% 75%, 
  50% 75%, 50% 50%, 
  25% 50%, 25% 25%, 
  0% 25%);
  position: relative;
  top: -1px;
}


.bottomflat-step-rtl {
  clip-path: polygon(0% 0%, 25% 0%,
  25% 2%, 50% 2%,
  50% 4%, 75% 4%,
  75% 6%, 100% 6%, 
  100% 100%, 0% 100%);
}

.pre-footer {
  position: relative;
  bottom: -43px;
}

a {
  color: var(--pink--dark);
  text-decoration: none;
}

a:hover {
  color: var(--pink--light);
  text-decoration: none;
}

.rex-border-top {
  border-top: solid;
  border-width: 2px;
  border-color: var(--orange-mid)
}

ul {
  list-style-type: square;
}

.rex-skinny-line {
  line-height: 95%;
}

.extra-pad {
  padding-bottom: 100px;
}

.rex-hpfive-box-text {
  width: 200px;
  text-align: center;
}

.rex-hpfive-button {
  position: relative;
  bottom: -52px;
  z-index: 3;
}

.rex-sticky-sidebar {
  position: -webkit-sticky;
  position: sticky;
  top: 150px;
  bottom: 150px;
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
  padding-right: 25px;
  scrollbar-color: var(--sev_low) var(--orange-mid);
  display: flex;
  flex-direction: column;
}

.rex-sticky-sidebar::-webkit-scrollbar {
  width: 5px;
}

.rex-sticky-sidebar::-webkit-scrollbar-track {
  background: lightgray;
  border-radius: 6px;
}
.rex-sticky-sidebar::-webkit-scrollbar-thumb {
  background-color: var(--orange-darkbright) ;
  border-radius: 6px;
}

.rex-screenshot {
  border: solid 2px var(--orange-mid);
  box-shadow: 10px 10px var(--orange-mid);
  height: auto;
}

.rex-screenshot-dark {
  border: solid 2px var(--darkorange);
  box-shadow: 10px 10px var(--darkorange);
  height: auto;
}

.android-logo {
  height: 35px;
  margin: -15px 10px 0px 0px;
}

.dino-icon {
  max-width: 100px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.hpfinal-content {
  max-width: 800px;
}

.blog-image {
  width: 25%;
  min-width: 200px;
}

.large-blog-image {
  width: 50%;
  min-width: 200px;
}

.blog-person {
  height: 110px;
}

.blog-hold-text {
  color: gray;
  font-style: italic;
}

.content-block-image {
  width: 250px;
}

.content-block-text {
  width: 450px;
}


@media
  only screen and (max-width: 1200px)  {
    .resp_grading table, .resp_grading thead, .resp_grading tbody, .resp_grading th, .resp_grading td, .resp_grading tr {
        display: block;
      }

    .resp_grading thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .resp_grading tr {
      margin: 0 0 1rem 0;
    }
      
    .resp_grading tr:nth-child(odd) {
      background: #ccc;
    }
    
    .resp_grading td {
      width: 100%;
      border: none;
      position: relative;
      padding-left: 10px;
    }

    .resp_grading td:before {
      position: relative;
      top: 0px;
      white-space: nowrap;
      font-weight: bold;
    }

    .resp_grading td:nth-of-type(1):before { content: "High: "; }
    .resp_grading td:nth-of-type(2):before { content: "Medium: "; }
    .resp_grading td:nth-of-type(3):before { content: "Low: "; }
}

.plans-contact-form {
  border: none;
  width: 100%;
  height: 650px;
}

.rex-dropdown {
  margin-top: 0px;
  border-radius: 0;
  border: solid 1px #fff;
  box-shadow: 5px 5px var(--orange-darkbright);
  background-color: var(--orange-mid);
  padding: 10px 10px;
}

.rex-dropdown-link {
  text-decoration: none;
  color: var(--darkorange);
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  font-size: small;
  font-weight: 600;
}

.rex-dropdown-link:hover {
  text-decoration: none;
  color: #fff;
}

/* css for articles from graphql */

.backend-content img {
  border: solid 2px var(--orange-mid);
  box-shadow: 10px 10px var(--orange-mid);
  height: auto;
  width: 50%; 
  margin-top: 30px;
  margin-bottom: 40px;
  align-self: center;
  text-align: center;
  margin-left: 25%;
  margin-right: 25%;
}

.backend-content hr {
  border: none;
  margin-top: 50px;
}

.backend-content strong {
  color: var(--darkorange);
  font-weight: 900;
  text-transform: uppercase;
}

.backend-content {
  word-wrap: break-word
}

/* spinner */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--orange-darkbright);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--orange-darkbright) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.bounce {
  height: 100px;
  top: 0px;
  transition: all .15s linear 0s;
  position: relative;
  display: inline-block;
}

.bounce:hover {
  top: 5px;
}

.button-clear {
  background: none;
  border: none;
  padding: none;
  margin: none;
}

.rex-table {
  width: 100%;
  background-color: #fff;
  margin: 10px 0px;
}

.rex-table tr {
  border-top: 1px solid var(--orange-mid);
  border-bottom: 1px solid var(--orange-mid);
}

.rex-table td, th {
  padding: 10px;
}